import * as React from 'react'
import Layout from '../components/layout'
import {Titulo} from '../components/titulo'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'

export default function admision () {
    const data = [
        {id: '1', title: 'Período principal de postulaciones.', date:'12 de agosto al 8 de septiembre de 2021'},
        {id: '2', title: 'Publicación de resultados.', date:'25 al 29 de octubre de 2021'},
        {id: '3', title: 'Publicación de resultados listas de espera.', date:'10 y 11 de noviembre de 2021'},
        {id: '4', title: 'Período complementario de postulación.', date:'23 al 30 de noviembre de 2021'},
        {id: '5', title: 'Publicación de resultados período complementario de postulación.', date:'14 de diciembre de 2021'},
        {id: '6', title: 'Período de matrícula en los establecimientos.', date:'15 al 27 de diciembre de 2021.'},
        {id: '7', title: 'Período de regularización exclusiva para repitentes.', date:'28 y 29 de diciembre de 2021'},
        {id: '8', title: 'Inicio período de regularización del año lectivo 2022.', date:'Desde el 30 de diciembre'}
    ]

    return (
        <Layout>
            <Titulo title="Proceso de admisión 2021" />
            <Container>
                <Row>
                    <Col md={12}>
                        <h3>Postula a través del 
                            <a href="https://www.sistemadeadmisionescolar.cl/" target="_blank" rel="noreferrer">
                                <StaticImage 
                                    src="../images/enlaces/sae_logo.svg" 
                                    alt="" 
                                    height={60}
                                />
                            </a>
                        </h3>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                    <Col md={12}><h3>Fechas importantes</h3></Col>
                    {data && data.map( e => (
                        <Col md={4} key={e.id}>
                            <Card className="mt-3 bg-dark text-center">
                                <Card.Body>
                                    <h5>{e.title}</h5>
                                    <h6>{e.date}</h6>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col md={12} className="mb-5"></Col>
                </Row>
                   
            </Container>

        </Layout>
    )
}